//展示Scratch范例组件,有可能是PPT也有可能是视频
<template>
  <div class="study_scratch">
    <!-- 内容 -->
    <div class="content" v-if="!showLoading">
      <div class="swiper-container">
        <div>
          <!-- 图片显示部分 -->
          <img
            class="swiper_img"
            :src="dataArr[currIndex] && dataArr[currIndex].img"
            v-if="dataArr[currIndex] && dataArr[currIndex].type == 1"
          />

          <div
            class="play_content"
            v-if="dataArr[currIndex] && dataArr[currIndex].type == 2"
          >
            <!-- 视频播放部分 -->
            <div class="player">
              <!-- 引入视频播放组件 -->
              <video-player
                class="video-player vjs-custom-skin video_style"
                :playsinline="true"
                :options="playerOptions"
                ref="my_video"
              ></video-player>
            </div>
          </div>
        </div>

        <!-- 左右按钮 -->
        <button
          type="button"
          :disabled="currIndex == 0"
          class="el-carousel__arrow el-carousel__arrow--left"
          :class="{ dis_cursore: currIndex == 0 }"
          v-show="currIndex != 0"
          @click="doPreAction"
        >
          <i class="el-icon-arrow-left"></i>
        </button>

        <button
          type="button"
          :class="{ dis_cursore: currIndex == dataArr.length - 1 }"
          v-show="currIndex != dataArr.length - 1"
          class="el-carousel__arrow el-carousel__arrow--right"
          @click="doNextAction"
        >
          <i class="el-icon-arrow-right"></i>
        </button>
      </div>
      <!-- 指示器显示 -->
      <div class="txt_indicator">{{ currIndex + 1 }}/{{ dataArr.length }}</div>
    </div>
    <!-- 加载框 -->
    <Loading v-if="showLoading"></Loading>
  </div>
</template>
<script>
//引入混入文件
import video from "../../mixin/video";
export default {
  name: "ScratchStudy",
  mixins: [video],
  data() {
    return {
      //页面需要展示的数据
      dataArr: [],
      //当前选中的索引
      currIndex: 0,
      //加载框
      showLoading: true,
    };
  },
  created() {
    //请求数据
    this.httpData();
  },
  mounted() {},
  methods: {
    //获取列表数据
    async httpData() {
      let param = this.getHttpParams();
      param.type = 4;
      param.curriculumCenterId = this.curriculumCenterId;
      param.curriculumChapterId = this.curriculumChapterId;
      param.chapterClassId = this.chapterClassId;
      param.sectionId = 3;
      let res = await this.$http.fetchPost(this.$api.LESSON_CENTER, param);
      this.showLoading = false;
      if (res.data.state == 200) {
        //表示请求成功
        let serverArr = res.data.data[0].learningContent;

        //根据服务器返回的数据组装页面数据
        if (!this.isEmpty(serverArr)) {
          serverArr.forEach((item) => {
            let obj = new Object();
            if (!this.isEmpty(item.pptPng)) {
              //证明是图片
              obj.type = 1;
              //配置图片地址
              obj.img = item.pptPng;
            } else {
              //证明是视频
              obj.type = 2;
              //配置封面
              obj.postUrl = item.videoCo;
              //配置视频地址
              obj.url = item.video;
            }
            this.dataArr.push(obj);
          });
          this.initData();
        }
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //第一次进入的时候设置第一个视频源和封面
    initData() {
      for (let i = 0; i < this.dataArr.length; i++) {
        let item = this.dataArr[i];
        if (item.type == 2) {
          //设置播放源和封面
          this.playerOptions.sources[0].src = item.url;
          this.playerOptions.poster = item.postUrl;
          //动态设置播放器的宽高比
          this.playerOptions.aspectRatio = "100:65";
          break;
        }
      }
    },

    //切换
    sysPos(pos) {
      if (this.currIndex != pos) {
        this.currIndex = pos;
        this.resetVideoPlayer();
      }
    },

    //上一步
    doPreAction() {
      if (this.currIndex > 0) {
        this.currIndex--;
        this.resetVideoPlayer();
      }
    },

    //下一步
    doNextAction() {
      if (this.currIndex < this.dataArr.length - 1) {
        this.currIndex++;
        this.resetVideoPlayer();
      }
    },

    //重置播放器的状态
    resetVideoPlayer() {
      //获取切换后的页面数据对象
      let item = this.dataArr[this.currIndex];

      if (item.type == 2) {
        //切换的页面是视频页面
        //更改封面
        this.playerOptions.poster = item.postUrl;

        //更改数据源
        this.playerOptions.sources[0].src = item.url;
      } else {
        if (!this.isEmpty(this.$refs.my_video)) {
          //改变播放器的播放源
          let myPlayer = this.$refs.my_video.player;
          if (!this.isEmpty(myPlayer)) {
            //切换到的页面是图片页面
            myPlayer.pause();
          }
        }
      }
    },
  },
  computed: {},
  components: {},
  props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
};
</script>
<style lang="scss" scoped>
.study_scratch {
  background: black;
  display: flex;
  align-items: center;
  flex: 1;
  .content {
    width: $common_width;
    height: 560px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    //swiper部分
    .swiper-container {
      width: 100%;
      flex: 1;
      position: relative;

      //图片部分
      .swiper_img {
        width: 100%;
        height: 520px;
        object-fit: contain;
      }

      .el-carousel__arrow {
        background: rgba($color: $common_bg, $alpha: 0.4);
      }

      .dis_cursore {
        cursor: not-allowed;
      }

      //底部的切换按钮
      .pagination_content {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;

        .item_outside {
          padding: 15px 4px 0px;
          cursor: pointer;
          .item_pagintion {
            background-color: #fff;
            width: 30px;
            height: 2px;
            margin: 0 6px;
          }
        }

        .active {
          opacity: 1;
        }

        .un_active {
          opacity: 0.48;
        }
      }

      //播放器部分
      .play_content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .player {
          margin: 0 auto;
          width: 65%;
          ::v-deep .vjs-poster {
            background-size: cover;
          }

          ::v-deep .video-player {
            height: 100%;
          }
          ::v-deep .vjs-custom-skin > .video-js {
            height: 100%;
          }
        }
      }
    }

    //指示器
    .txt_indicator {
      margin-top: 16px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>